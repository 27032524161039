<template>
  <div id="app">
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" @click="back"> </a>
      </div>
    </nav>
    <figure class="logo"><img src="@/assets/logo.png" /></figure>
    <div class="campaign-select">
      <select
        v-model="selected"
        class="selectpicker show-tick"
        data-none-selected-text="กรุณาเลือก campaign"
      >
        <option v-for="{ id, name } in campaigns" :key="id" :value="id">
          {{ name }}
        </option>
      </select>
    </div>
    <div class="banner">
      <img v-if="banner" :src="banner" />
    </div>
    <div class="container" v-if="campaign">
      <div class="custom-text" v-html="campaign.custom_text"></div>
      <div class="current-user">
        <table class="table">
          <tr>
            <td>{{ current.rank }}</td>
            <td>{{ current.name }}</td>
            <td>{{ current.progress }}</td>
            <td>{{ current.reward_text }}</td>
          </tr>
        </table>
      </div>
      <div class="top-user-table">
        <table class="table">
          <thead>
            <tr>
              <th>อันดับ</th>
              <th>ร้านค้า</th>
              <th>คะแนน</th>
              <th>รางวัล</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="({ rank, name, progress, reward_text }, i) in top"
              :key="i"
            >
              <td>{{ rank }}</td>
              <td>{{ name }}</td>
              <td>{{ progress }}</td>
              <td
                :style="{
                  width: `${120 - getScrollbarWidth()}px`,
                }"
              >
                {{ reward_text }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
const APP_SECRET_KEY = process.env.VUE_APP_SECRET_KEY;

export default {
  name: "LeaderBoard",

  data() {
    return {
      user: null,
      token: null,
      campaigns: [],
      selected: null,
      campaign: null,
      top: [],
      current: null,
    };
  },

  computed: {
    banner() {
      return this.campaign && this.campaign.banner_picture
        ? this.campaign.banner_picture
        : "";
    },
  },

  watch: {
    selected() {
      this.onSelectedCampaign();
    },
  },

  methods: {
    async login() {
      const { query, QUERY } = this.$route.query;
      const sfdcToken = query || QUERY;

      const { data } = await axios.post(
        `${API_ENDPOINT}/sfdc/api/game_leaderboard/login`,
        {
          query: sfdcToken,
        },
        {
          headers: {
            "x-application-secret-key": APP_SECRET_KEY,
          },
        }
      );

      const { user, token } = data;
      this.user = user;
      this.token = token;
    },

    async fetchAllCampaign() {
      const { data } = await axios.get(
        `${API_ENDPOINT}/sfdc/api/game_leaderboard/campaign`,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );

      this.campaigns = data.filter((o) => o.id != null);
    },

    async fetchCampaignInfo() {
      const { data } = await axios.get(
        `${API_ENDPOINT}/sfdc/api/game_leaderboard/campaign/${this.selected}`,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );

      const { user, top, campaign } = data;

      this.top = top;
      this.current = user;
      this.campaign = campaign;
    },

    async onSelectedCampaign() {
      if (!this.selected) return;
      this.fetchCampaignInfo();
    },

    back() {
      this.$router.back();
    },

    triggerRender() {
      $("select").selectpicker("destroy");
      $("select").selectpicker();

      if (this.campaigns.length > 0) this.selected = this.campaigns[0]["id"];
      if (this.selected != null) {
        $("select").val(this.selected);
        $("select").selectpicker("refresh");
      }
    },

    getScrollbarWidth() {
      // Creating invisible container
      const outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.overflow = "scroll"; // forcing scrollbar to appear
      outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
      document.body.appendChild(outer);

      // Creating inner element and placing it in the container
      const inner = document.createElement("div");
      outer.appendChild(inner);

      // Calculating difference between container's full width and the child width
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer);

      return scrollbarWidth;
    },
  },

  async created() {
    await this.login();
    await this.fetchAllCampaign();

    this.triggerRender();
  },
};
</script>

<style lang="scss">
@import "~@/styles/app.scss";
</style>
